import * as React from "react";
export const CounterContext = React.createContext<{
  setShowCounterPage: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  hidden: boolean | undefined;
  showCounterPage: boolean | undefined;
  setHidden: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}>({
  setShowCounterPage: undefined,
  hidden: false,
  showCounterPage: true,
  setHidden: undefined,
});

export default function CounterContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [showCounterPage, setShowCounterPage] = React.useState(true);
  const [hidden, setHidden] = React.useState(false);
  return (
    <CounterContext.Provider
      value={{
        showCounterPage,
        hidden,
        setHidden,
        setShowCounterPage,
      }}
    >
      {children}
    </CounterContext.Provider>
  );
}
