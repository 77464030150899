import "./src/styles/global.scss";
import * as React from "react";
import { MixpanelContext } from "./src/tracking.ts";
import mixpanel from "mixpanel-browser";
import "swiper/css";
import "swiper/css/pagination";
import CounterContextProvider from "./src/context/CounterContext";
export const wrapRootElement = ({ element }) => {
  mixpanel.init("5dedf7b7f1fd16cb3a9ba594ac7dc251", {
    debug: process.env.NODE_ENV === "development",
    ignore_dnt: true,
  });
  mixpanel.track("Page view");
  return (
    <CounterContextProvider>
      <MixpanelContext.Provider value={{}}>{element}</MixpanelContext.Provider>
    </CounterContextProvider>
  );
};
